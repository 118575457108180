/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails {
    getCurrentUserDetails
  }
`;
export const listUserTransactions = /* GraphQL */ `
  query ListUserTransactions {
    listUserTransactions
  }
`;
export const listUserCallHistory = /* GraphQL */ `
  query ListUserCallHistory {
    listUserCallHistory
  }
`;
export const listProfessions = /* GraphQL */ `
  query ListProfessions {
    listProfessions
  }
`;
export const listAnyquireSettings = /* GraphQL */ `
  query ListAnyquireSettings {
    listAnyquireSettings
  }
`;
export const bardAssistantModel = /* GraphQL */ `
  query BardAssistantModel($input: bardAssistantModelInput) {
    bardAssistantModel(input: $input)
  }
`;
export const listSearchKeywords = /* GraphQL */ `
  query ListSearchKeywords {
    listSearchKeywords
  }
`;
