<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  name: "App",

  data: () => ({
    //
  }),

  async mounted() {},
  methods: {},
};
</script>

<style>
.FontSize9px {
  font-size: 9px !important;
}
.FontSize10px {
  font-size: 10px !important;
}
.FontSize12px {
  font-size: 12px !important;
}
.FontSize14px {
  font-size: 14px !important;
}
.FontSize16px {
  font-size: 16px !important;
}
.FontSize18px {
  font-size: 18px !important;
}
.FontSize20px {
  font-size: 20px !important;
}
.FontSize24px {
  font-size: 24px !important;
}
.FontSize30px {
  font-size: 30px !important;
}
.FontSize40px {
  font-size: 40px !important;
}
.maxWidthVariant1 {
  max-width: 50% !important;
}
.maxWidthVariant2 {
  max-width: 150px !important;
}
.maxWidthVariant3 {
  max-width: 150px !important;
}
.maxWidthVariant4 {
  max-width: 100px !important;
}

.borderRadiusVariant1 {
  border-radius: 40px !important;
}
.cardOutline {
  border: 1px solid #a3a1a1 !important;
}
.RoundBorderRadius {
  border-radius: 30px !important;
}
.CursorPointer {
  cursor: pointer !important;
}
.borderVariant1 {
  border: 1px solid #fff !important;
  background: transparent !important;
}
.buttonGradient {
  background: linear-gradient(45deg, #ff00cc, #3333ff, #00ffcc) !important;
}
.textDecorationVariant1 {
  text-decoration: underline !important;
  cursor: pointer !important;
}
.fontFamilyVariant1 {
  font-family: Gabriola, Times, serif !important;
  font-size: 70px !important;
  margin-top: 6% !important;
}
.fontFamilyVariant2 {
  font-family: Gabriola, Times, serif !important;
  font-size: 35px !important;
}
.CardGradient {
  background: linear-gradient(45deg, #ff00cc, #3333ff, #00ffcc);
  background-size: 400% 400%;
  animation: gradientAnimation 8s ease infinite !important;
}
.gradienttextLetter {
  font-family: Gabriola, Times, serif;
  background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.InitiateCallloader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 14px;
  clip-path: inset(0 3ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.InitiateCallloader:before {
  content: "Initiating Your Call...";
  padding-left: 20px !important;
}
@keyframes l4 {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
.textDecoration:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
}
.TableClass table {
  border-collapse: separate !important;
  border-spacing: 0 8px !important;
}
.TableClass th {
  background-image: linear-gradient(to bottom, #3064f4c0, #6617cee8) !important;
  font-size: 14px !important;
  color: #fff !important;
}
.TableClass td {
  background-image: linear-gradient(to bottom, #3064f465, #6617ce4b) !important;
  font-size: 12px !important;
  color: #000 !important;
}
</style>
