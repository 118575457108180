import axios from "axios";
import { Uploadile } from "@/mixins/UploadFile.js";
export const GlobalSearch = {
  mixins: [Uploadile],
  data: () => ({
    ListAllSearchResult: [],
    TopRatedExperts: [],
    FindResult: false,
    ErrorMessage: "",
  }),
  methods: {
    async GlobalSearchMethod(searchinput, index_name) {
      this.ListAllSearchResult = [];
      try {
        console.log("Search Imnput", searchinput);
        this.EnableSkeltonLoader = true;

        let data = {
          command: "searchData",
          index_name: index_name,
          search_input: searchinput,
        };
        var config = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "IjTmpS9QCM8QcrIZZ2pHy64TS6oPdw6A7In2K1M7",
          },
          url: "https://6buq4qbi46.execute-api.ap-south-1.amazonaws.com/Any/search_data",
          data: data,
        };
        console.log("Coonfig", config);
        let result = await axios(config);
        if (result.data.status == "SUCCESS") {
          this.EnableSkeltonLoader = false;
          this.FindResult = true;
          this.ListAllSearchResult = result.data.data;
          for (let i = 0; i < this.ListAllSearchResult.length; i++) {
            this.ListAllSearchResult[i]._source.PreSignedURlImage = await this.GetPreSignedUrlFilesFromS3(
              this.ListAllSearchResult[i]._source.search_type === "expert"
                ? this.ListAllSearchResult[i]._source.expert_profile_pic_url
                : this.ListAllSearchResult[i]._source.sponsor_logo
            );
            this.ListAllSearchResult[i]._source.IsCallInitiated = false;
          }
          this.$forceUpdate();
        } else {
          this.ErrorMessage = result.data.errorMessage;
          this.EnableSkeltonLoader = false;
        }
        // this.EnableSkeltonLoader = false;
        console.log("Result", this.ListAllSearchResult);
        return result;
      } catch (error) {
        this.EnableSkeltonLoader = false;
        this.FindResult = false;
        this.ListAllSearchResult = [];
        console.log("Errr", error);
      }
    },
    async GetTopRatedExpertsMethod(subDomain) {
      try {
        this.TopRatedExperts = [];
        let data = {
          command: "listTopRatedExperts",
          expert_profession: subDomain,
        };
        var config = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "IjTmpS9QCM8QcrIZZ2pHy64TS6oPdw6A7In2K1M7",
          },
          url: "https://6buq4qbi46.execute-api.ap-south-1.amazonaws.com/Any/listTopRatedExperts",
          data: data,
        };
        let Result = await axios(config);
        if (Result.data.status === "SUCCESS") {
          this.TopRatedExperts = Result.data.data;
          for (let i = 0; i < this.TopRatedExperts.length; i++) {
            this.TopRatedExperts[i].PreSignedURlImage = await this.GetPreSignedUrlFilesFromS3(
              this.TopRatedExperts[i].expert_profile_pic_url
            );
          }
          this.$forceUpdate();
        }
      } catch (error) {
        console.log("Err", error);
      }
    },
  },
};
