import axios from "axios";

export const GetAllProfession = {
  data: () => ({
    GetAllUpdatedProfession: [],
    listAllProfession: [],
    listSubProfession: [],
    ListSearchKeyWords: [],
  }),
  methods: {
    async GetAllProfessionMethod() {
      try {
        let config = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "IjTmpS9QCM8QcrIZZ2pHy64TS6oPdw6A7In2K1M7",
          },
          url: "https://6buq4qbi46.execute-api.ap-south-1.amazonaws.com/Any/list_professions",
          data: { profession_status: "ACTIVE" },
        };
        let result = await axios(config);

        if (result.data.status == "Success") {
          if (this.indexName === "anyquire" || this.indexName === "localhost") {
            this.listAllProfession = result.data.data.items;
            // this.GetAllUpdatedProfession.unshift({ profession_name: "Top Rated" });
            this.listAllProfession.map((item) => {
              this.GetAllUpdatedProfession.push({ profession_name: item.profession_name });
            });
            this.GetAllUpdatedProfession.sort((a, b) => {
              return a.profession_name.localeCompare(b.profession_name);
            });
            this.$forceUpdate();
          } else {
            for (let i = 0; i < result.data.data.items.length; i++) {
              if (this.indexName === result.data.data.items[i].profession_name.replaceAll(" ", "").toLowerCase()) {
                this.GetSubProfessionMethod(result.data.data.items[i].profession_id);
                // this.indexName = hostname;
              }
            }
          }
        }
        console.log("rresu;lt", result);
      } catch (error) {
        console.log("Err", error);
      }
    },
    async GetSubProfessionMethod(master_profession_id) {
      try {
        console.log(" i m Coming Inside of SubProfessiion Method");
        let config = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "IjTmpS9QCM8QcrIZZ2pHy64TS6oPdw6A7In2K1M7",
          },
          url: "https://6buq4qbi46.execute-api.ap-south-1.amazonaws.com/Any/list_professions",
          data: { master_profession_id, profession_status: "ACTIVE" },
        };
        let result = await axios(config);
        if (result.data.status == "Success") {
          this.listSubProfession = result.data.data.items;
          // this.listSubProfession.unshift({ profession_name: "Top Rated" });
          this.listSubProfession.map((item) => {
            this.GetAllUpdatedProfession.push({ profession_name: item.profession_name });
          });
          this.GetAllUpdatedProfession.sort((a, b) => {
            return a.profession_name.localeCompare(b.profession_name);
          });
        }
        console.log("listSubProfession", this.listSubProfession);
      } catch (error) {
        console.log("Err", error);
      }
    },
    async GetAllSearchKeyWordsmethod() {
      try {
        let config = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "IjTmpS9QCM8QcrIZZ2pHy64TS6oPdw6A7In2K1M7",
          },
          url: "https://6buq4qbi46.execute-api.ap-south-1.amazonaws.com/Any/list_search_keywords",
          data: { command: "listSearchKeywords" },
        };
        let result = await axios(config);
        if (result.data.status == "SUCCESS") {
          this.ListSearchKeyWords = result.data.data;
        }
        console.log("listSubProfession", this.ListSearchKeyWords);
      } catch (error) {
        console.log("Err", error);
      }
    },
  },
};
