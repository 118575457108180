import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { Amplify } from "aws-amplify";
Vue.config.productionTip = false;
Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_dW6dlVcmO",
    region: "ap-south-1",
    userPoolWebClientId: "5t8btdq2odntfgodd6rfd7nplp",
  },
  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint: "https://a646y3o64bgjfowyosbfwejzkm.appsync-api.ap-south-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
});
new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
