import { API, graphqlOperation } from "aws-amplify";
import { getCurrentUserDetails } from "@/graphql/queries.js";

export const GetCurrentUser = {
  methods: {
    async GetCurrentUserMethod() {
      try {
        let result = await API.graphql(
          graphqlOperation(getCurrentUserDetails, {
            input: {},
          })
        );
        console.log("Result Today Current", result);
        let ResultObj = JSON.parse(result.data.getCurrentUserDetails);
        this.drawer = false;
        sessionStorage.setItem("SET_CURRENT_USER_DETAILS", JSON.stringify(ResultObj.data.items[0]));
        sessionStorage.setItem("SET_USER_LOGGED_IN", true);
        this.IsUserLoggedIn = true;
        this.CurrentUser = ResultObj.data.items[0];
      } catch (error) {
        sessionStorage.clear();
        this
        console.log("err", error);
      }
    },
  },
};
