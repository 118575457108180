import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import HomePage from "../views/ParentPages/HomePage.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },

  {
    path: "/SignIn",
    name: "SignIn",
    component: () => import(/* webpackChunkName: "HomePage" */ "../views/ParentPages/SignIn.vue"),
  },
  {
    path: "/MyProfile",
    name: "MyProfile",
    component: () => import(/* webpackChunkName: "HomePage" */ "../views/ParentPages/MyProfile.vue"),
  },
  {
    path: "/:GetExpertName",
    name: "ExpertsDetails",
    component: () => import(/* webpackChunkName: "HomePage" */ "../views/ParentPages/ExpertsDetails.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
