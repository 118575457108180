<template>
  <div>
    <v-app-bar flat color="transparent" v-if="!IsUserLoggedIn">
      <v-img
        @click="RouteToHomePageMethod()"
        max-width="150px"
        class="CursorPointer mt-3"
        src="@/assets/anyqurue-logo.png"
      />
      <v-text-field
        outlined
        autofocus
        v-if="current_view === 'SEARCH_RESULT'"
        dense
        class="mt-8 ml-6 maxWidthVariant1 borderRadiusVariant1"
        v-model="SearchInput"
        clear-icon="mdi-close"
        clearable
        @keydown.enter="SearchResultMethod"
      ></v-text-field>
      <v-spacer />

      <v-btn v-if="!IsUserLoggedIn" depressed tile class="mt-2" color="primary" @click="drawer = !drawer">SignIn</v-btn>
    </v-app-bar>
    <v-toolbar
      dense
      class="mt-0 pt-0 mx-16 px-16"
      max-width="1000px"
      color="transparent"
      flat
      v-if="current_view === 'SEARCH_RESULT' && !IsUserLoggedIn"
    >
      <v-chip-group v-model="SelectedChip" active-class="primary--text" class="pa-0">
        <v-chip label small v-for="category in ListSearchKeyWords" :key="category" :value="category">
          {{ category }}
        </v-chip>
      </v-chip-group>
    </v-toolbar>
    <v-app-bar flat color="transparent" v-if="IsUserLoggedIn">
      <v-img
        @click="RouteToHomePageMethod()"
        max-width="120px"
        class="CursorPointer"
        src="@/assets/anyqurue-logo.png"
      />
      <v-divider vertical class="mx-4 my-n1" v-if="current_view === 'SEARCH_RESULT'" />
      <v-btn
        :color="CurrentSelectedPage === btn.value ? 'primary' : ''"
        depressed
        tile
        small
        class="ml-2 text-capitalize BorderRadius"
        v-for="(btn, btnIndex) in MenuOptions"
        :key="btnIndex"
        @click="CommonRouteMethod(btn.value)"
        >{{ btn.text }}</v-btn
      >
      <v-spacer />
      <div class="pt-4">
        <v-card-text>
          <div>{{ CurrentUser.consumer_phone_number.replace("+91", "+91-") }} (User)</div>
          <div class="text-center">Balance: {{ Number(CurrentUser.consumer_credits).toFixed(2) }} Credits</div>
        </v-card-text>
      </div>
      <v-btn icon class="" color="red" @click="SignOutMethod()"><v-icon>mdi-logout</v-icon></v-btn>
    </v-app-bar>
    <v-card
      flat
      max-width="1000px"
      class="mx-16 px-10"
      max-height="50px"
      v-if="IsUserLoggedIn && current_view === 'SEARCH_RESULT'"
    >
      <v-text-field
        outlined
        autofocus
        v-if="current_view === 'SEARCH_RESULT'"
        dense
        class="ml-16 maxWidthVariant1 borderRadiusVariant1"
        v-model="SearchInput"
        clear-icon="mdi-close"
        clearable
        @keydown.enter="SearchResultMethod"
      ></v-text-field>
      <v-chip-group v-model="SelectedChip" active-class="primary--text" class="mt-n5 px-3">
        <v-chip label small v-for="category in ListSearchKeyWords" :key="category" :value="category">
          {{ category }}
        </v-chip>
      </v-chip-group>
    </v-card>
    <v-card v-if="current_view === 'OPEN_SEARCH'" flat :height="$vuetify.breakpoint.height - 80" tile>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex>
            <v-row>
              <v-col cols="12" xs="12" md="12" sm="12" align="center" class="mt-n16">
                <v-card flat class="text-center" color="transparent">
                  <v-img contain height="150px" src="@/assets/anyqurue-logo.png" />
                </v-card>
                <v-card flat color="transparent">
                  <v-text-field
                    label="Search for providers by keyword"
                    outlined
                    autofocus
                    clear-icon="mdi-close"
                    clearable
                    v-model="SearchInput"
                    prepend-inner-icon="mdi-magnify"
                    :class="$vuetify.breakpoint.mdAndUp ? 'maxWidthVariant1' : ''"
                    class="FontSize20px mt-3 borderRadiusVariant1"
                    @keydown.enter="SearchResultMethod"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense class="mt-n8">
              <v-col cols="12" md="12" align="center" class="mx-4">
                <v-card flat width="850px" class="mx-auto px-5 text-center">
                  <v-chip-group column active-class="primary--text" v-model="HomePageChip">
                    <v-chip
                      small
                      label
                      flat
                      class="font-weight-bold FontSize12px"
                      v-for="(SearchKey, SearchKeyIndex) in ListSearchKeyWords"
                      :key="SearchKeyIndex"
                      :value="SearchKey"
                    >
                      {{ SearchKey }}
                    </v-chip>
                  </v-chip-group>
                </v-card>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <v-card
      v-if="current_view === 'SEARCH_RESULT'"
      color="#f2f2f2"
      flat
      :height="$vuetify.breakpoint.height - 150"
      class="pb-5 overflow-auto"
      tile
      :class="IsUserLoggedIn ? 'mt-8' : ''"
    >
      <SkeletonLoader v-if="EnableSkeltonLoader" />
      <div
        v-show="ListAllSearchResult.length !== 0"
        v-for="(filteredUser, index) in ListAllSearchResult"
        :key="index"
        class="pt-0 mt-0 mx-16 px-16 overflow-auto"
      >
        <v-card
          tile
          min-height="180px"
          flat
          color="white"
          class="mt-2 overflow-x-hidden"
          @click="CheckSelectedUserMethods(filteredUser._source)"
        >
          <v-card-text class="">
            <v-row no-gutters>
              <v-col cols="12" xs="12" md="12" sm="12">
                <v-card-actions class="ma-0 pa-0">
                  <div class="FontSize20px font-weight-bold primary--text text-capitalize">
                    <span>
                      <v-avatar size="40" v-if="filteredUser._source.PreSignedURlImage">
                        <v-img :src="filteredUser._source.PreSignedURlImage" />
                      </v-avatar>
                      <v-avatar color="black" size="40" v-else>
                        <v-img src="@/assets/transparent_logo.png" />
                      </v-avatar>
                    </span>
                  </div>
                  <div>
                    <v-card-text class="mt-n2">
                      <div
                        class="FontSize14px font-weight-bold primary--text"
                        v-if="filteredUser._source.expert_service_name && filteredUser._source.search_type === 'EXPERT'"
                      >
                        {{ filteredUser._source.expert_service_name }}
                      </div>
                      <div
                        class="FontSize14px font-weight-bold primary--text"
                        v-if="filteredUser._source.search_type === 'SPONSOR'"
                      >
                        {{ filteredUser._source.sponsor_name_of_organization }}
                      </div>
                      <div
                        class="FontSize14px font-weight-bold primary--text"
                        v-if="filteredUser._source.search_type === 'SPONSOR_TOPIC'"
                      >
                        {{ filteredUser._source.sponsor_topic_name }}
                      </div>
                      <div class="FontSize14px font-weight-bold">
                        {{
                          filteredUser._source.search_type === "EXPERT"
                            ? filteredUser._source.expert_name
                            : filteredUser._source.search_type === "SPONSOR"
                            ? `${filteredUser._source.sponsor_type_of_organization}, ${filteredUser._source.sponsor_industry_sector_type}`
                            : filteredUser._source.search_type === "SPONSOR_TOPIC"
                            ? filteredUser._source.sponsor_name_of_organization
                            : ""
                        }}
                      </div>
                    </v-card-text>
                  </div>
                  <v-spacer />
                  <div class="font-weight-bold" v-if="filteredUser._source.search_type === 'EXPERT'">
                    <span v-if="filteredUser._source.expert_charge_per_minute !== 0">&#8377;</span
                    >{{
                      filteredUser._source.expert_charge_per_minute === 0
                        ? "FREE"
                        : `${filteredUser._source.expert_charge_per_minute}/min`
                    }}
                  </div>
                  <div class="font-weight-bold" v-if="filteredUser._source.search_type === 'SPONSOR_TOPIC'">
                    <span v-if="filteredUser._source.sponsor_topic_charge_per_min !== 0">&#8377;</span
                    >{{
                      filteredUser._source.sponsor_topic_charge_per_min === 0
                        ? "FREE"
                        : `${filteredUser._source.sponsor_topic_charge_per_min}/min`
                    }}
                  </div>
                </v-card-actions>
                <!-- <div class="FontSize18px text-capitalize mt-2">
                  {{ filteredUser._source.expert_profession }}
                </div> -->
                <div
                  class="FontSize12px text-capitalize px-14 mt-n3"
                  v-if="filteredUser._source.search_type === 'EXPERT'"
                >
                  {{ filteredUser._source.expert_state }}, {{ filteredUser._source.expert_country }}
                </div>
                <v-chip-group column class="" v-if="filteredUser._source.search_type === 'EXPERT'">
                  <v-chip
                    small
                    v-for="(language, index) in typeof filteredUser._source.expert_languages === 'string'
                      ? JSON.parse(filteredUser._source.expert_languages)
                      : filteredUser._source.expert_languages"
                    :key="index"
                    label
                  >
                    {{ language }}
                  </v-chip>
                </v-chip-group>
                <!-- <div
                  v-if="filteredUser._source.search_type === 'SPONSOR'"
                  class="FontSize12px text-capitalize px-11 mt-n3"
                >
                  {{ filteredUser._source.sponsor_address }}
                </div> -->
                <div class="pt-3 text-justify" v-if="filteredUser._source.search_type === 'EXPERT'">
                  {{
                    filteredUser._source.expert_long_description.length <= 300
                      ? filteredUser._source.expert_long_description
                      : filteredUser._source.expert_long_description.slice(0, 300)
                  }}
                </div>
                <div class="pt-3 text-justify" v-if="filteredUser._source.search_type === 'SPONSOR'">
                  {{
                    filteredUser._source.sponsor_profile_description.length <= 300
                      ? filteredUser._source.sponsor_profile_description
                      : filteredUser._source.sponsor_profile_description.slice(0, 300)
                  }}
                </div>

                <div class="pt-3 text-justify" v-if="filteredUser._source.search_type === 'SPONSOR_TOPIC'">
                  {{
                    filteredUser._source.sponsor_topic_description.length <= 300
                      ? filteredUser._source.sponsor_topic_description
                      : filteredUser._source.sponsor_topic_description.slice(0, 300)
                  }}
                </div>
                <!-- <v-rating
                    icon-label="Rating"
                    v-model="filteredUser._source.expert_rating"
                    :length="5"
                    color="#FFD700"
                    background-color="grey lighten-1"
                    small
                  ></v-rating> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <div v-if="ListAllSearchResult.length === 0 && EnableSkeltonLoader == false">
        <v-card flat color="transparent" class="overflow-hidden" :height="$vuetify.breakpoint.height - 300">
          <v-container fluid fill-height>
            <v-layout align-center justify-center>
              <v-flex>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" align="center">
                    <v-img contain max-height="500px" src="@/assets/noResultTransparent.png" />
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </div>
    </v-card>
    <v-navigation-drawer v-if="drawer" color="primary" v-model="drawer" absolute bottom temporary right width="400px">
      <v-card height="auto" :width="$vuetify.breakpoint.width - 600" color="transparent" tile flat>
        <v-toolbar color="primary" dense tile flat dark>
          <div class="FontSize24px FontFamilyVariant1 text-left">User SignIn</div>
        </v-toolbar>
        <v-form ref="SignInForm" class="pt-5 pb-3">
          <v-row no-gutters dense class="mx-5">
            <v-col cols="12" xs="12" md="5">
              <v-select
                outlined
                dense
                solo
                flat
                class="maxWidthVariant2"
                :items="CountryCodeItems"
                label="Country Code"
                :rules="[(v) => !!v || 'required']"
                single-line
                v-model="SignIn.countryCode"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" md="7">
              <v-text-field
                outlined
                autofocus
                dense
                flat
                solo
                class="ml-2 custom-error-color"
                :rules="[
                  (v) => !!v || 'Mobile number is required',
                  (v) => /^[0-9]{10}$/.test(v) || 'Mobile number must be 10 digits and numeric',
                ]"
                label="Contact Number"
                maxLength="10"
                single-line
                v-model="SignIn.contactNumber"
              ></v-text-field>
            </v-col>
          </v-row>

          <div class="text-justify mx-5 white--text" v-if="EnableOTPField">
            Please enter the OTP sent to your mobile number for verification.
          </div>
          <v-otp-input
            v-show="EnableOTPField"
            ref="OTPField"
            autofocus
            length="6"
            class="mx-5"
            type="number"
            v-model="SignIn.Otp"
          ></v-otp-input>
          <v-card-actions class="pa-0 ma-0 mx-5"
            >``
            <v-btn
              depressed
              tile
              outlined
              :loading="LoginLoader"
              color="white"
              @click="ValidateMethod(EnableOTPField ? 'VERIFY_OTP' : 'SEND_OTP')"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-navigation-drawer>
    <v-footer height="50px" fixed tile color="#e0e0e0" class="m px-5">
      <v-row class="p px-5 space-around">
        <v-col cols="4" v-if="!IsUserLoggedIn">
          <span text class="pl-5 text-capitalize FontSize12px textDecoration" @click="RouteMethod('EXPERT_PORTAL')"
            >Sign In as Provider</span
          >
        </v-col>
        <v-col cols="8" :align="IsUserLoggedIn ? 'left' : 'right'">
          <span class="text-right FontSize12px"
            ><v-icon small class="pr-2">mdi-copyright</v-icon>{{ new Date().getFullYear() }}
            <strong>Mobil80 Services and Solutions Pvt. Ltd. All Rights Reserved.</strong>
          </span>
        </v-col>
      </v-row>
      <v-spacer />
      <span class="FontSize12px textDecoration" @click="RouteMethod('PRIVACY_PORTAL')">Privacy</span>
      <span class="pl-5 FontSize12px textDecoration" @click="RouteMethod('TERMS_OF_SERVICE_PORTAL')"
        >Terms & Condition</span
      >
      <span class="FontSize12px pl-5">Version - 0.0.5</span>
    </v-footer>
  </div>
</template>

<script>
import { GetAllProfession } from "@/mixins/GetAllProfessions.js";
import { GlobalSearch } from "@/mixins/GlobalSearch.js";
import { GetCurrentUser } from "@/mixins/GetUserDetails.js";
import SkeletonLoader from "@/components/Extras/SkeletonLoader.vue";
import { Auth } from "aws-amplify";
import axios from "axios";
export default {
  components: {
    SkeletonLoader,
  },
  mixins: [GetCurrentUser, GlobalSearch, GetAllProfession],
  data: () => ({
    CurrentSelectedPage: "HOME",
    SelectedChip: "",
    HomePageChip: "",
    SearchTab: 0,
    SearchInput: "",
    drawer: false,
    TermsAndConditonDialog: false,
    TermsAndConditionValue: false,
    EnableSkeltonLoader: false,
    indexName: "",
    EnableToastMessage: false,
    AgreeSignUpLoader: false,
    ResponseColor: "",
    ResponseMessage: "",
    EnableOTPField: false,
    LoginLoader: false,
    IsUserLoggedIn: false,
    current_view: "OPEN_SEARCH",
    user: null,
    CurrentUser: {},
    SignIn: {
      countryCode: "+91",
      contactNumber: "",
      Otp: "",
    },
    MenuOptions: [
      {
        text: "Home",
        value: "HOME",
      },
      {
        text: "Recharge",
        value: "RECHARGE",
      },
      {
        text: "Call History",
        value: "CALL_HISTORY",
      },
      {
        text: "Transaction History",
        value: "TRANSACTION_HISTORY",
      },
    ],
    CountryCodeItems: ["+91"],
  }),
  watch: {
    SearchInput(val) {
      sessionStorage.setItem("LAST_SEARCH", val);
    },
    current_view(val) {
      if (val === "OPEN_SEARCH") {
        this.SearchInput = "";
      }
    },
    HomePageChip(val) {
      if (val != "") {
        this.SearchInput = val;
        this.current_view = "SEARCH_RESULT";
        this.SelectedChip = val;
        this.GlobalSearchMethod(val, "experts");
      }
    },
    SelectedChip(val) {
      if (val != "") {
        this.SearchInput = val;
        this.GlobalSearchMethod(val, "experts");
      }
    },
  },
  computed: {
    GetListProfessionItemMethod() {
      if (this.indexName === "anyquire" || this.indexName === "localhost") {
        return this.listAllProfession;
      } else {
        return this.listSubProfession;
      }
    },
  },
  async mounted() {
    console.log("Vuetify Breakpoint", this.$vuetify);
    // history.pushState(null, null, location.href);
    // window.onpopstate = function () {
    //   history.go(1);
    //   this.SearchInput = "";
    // };
    const hostname = window.location.hostname.toLowerCase().replace("www.", "").split(".")[0];
    this.indexName = hostname;
    // if (sessionStorage.getItem("VISIBLE_CURRENT_PAGE") === "SEARCH_RESULT") {
    //   if (sessionStorage.getItem("LAST_SEARCH") != "" && sessionStorage.getItem("LAST_SEARCH") != null) {
    //     this.SearchInput = sessionStorage.getItem("LAST_SEARCH");
    //     console.log("Check For Last Response", this.ListAllSearchResult);
    //     this.GlobalSearchMethod(
    //       this.SearchInput,
    //       this.indexName === "anyquire" || this.indexName === "localhost" ? "experts" : this.indexName
    //     );
    //   } else {
    //     this.SearchInput = "";
    //   }
    //   this.current_view = "SEARCH_RESULT";
    // } else {
    //   this.current_view = "OPEN_SEARCH";
    //   this.SearchInput = "";
    //   sessionStorage.setItem("VISIBLE_CURRENT_PAGE", "OPEN_SEARCH");
    // }
    this.current_view = "OPEN_SEARCH";
    this.IsUserLoggedIn = sessionStorage.getItem("SET_USER_LOGGED_IN");
    this.CurrentUser = JSON.parse(sessionStorage.getItem("SET_CURRENT_USER_DETAILS"));
    this.GetAllSearchKeyWordsmethod();
    // addEventListener("popstate", (event) => {
    //   console.log("Event", event);
    //   this.current_view = "OPEN_SEARCH";
    // });
  },
  methods: {
    SearchResultMethod() {
      sessionStorage.setItem("VISIBLE_CURRENT_PAGE", "SEARCH_RESULT");
      this.GlobalSearchMethod(
        this.SearchInput,
        this.indexName === "anyquire" || this.indexName === "localhost" ? "experts" : this.indexName
      );
      this.current_view = "SEARCH_RESULT";
    },
    RouteMethod(action) {
      switch (action) {
        case "EXPERT_PORTAL":
          window.open("http://provider.anyquire.com/", "__blank");
          break;
        case "PRIVACY_PORTAL":
          window.open("https://privacypolicy.anyquire.com/", "__blank");
          break;
        case "TERMS_OF_SERVICE_PORTAL":
          window.open("https://termsofservice.anyquire.com/", "__blank");
          break;
      }
    },
    RouteToHomePageMethod() {
      this.current_view = "OPEN_SEARCH";
      this.SearchInput = "";
      this.SelectedChip = "";
      this.HomePageChip = "";
      this.$forceUpdate();
    },
    MyProfileMethod() {
      window.history.pushState({ view: this.current_view }, null, null);
      this.$router.push({ name: "MyProfile" });
    },
    CheckSelectedUserMethods(SelectedUser) {
      // if (SelectedUser.search_type === "EXPERT") {
      this.$store.commit("SET_EXPERT_DETAILS", SelectedUser);
      this.$router.push(
        `${
          SelectedUser.search_type === "EXPERT"
            ? SelectedUser.expert_display_id
            : SelectedUser.search_type === "SPONSOR"
            ? SelectedUser.sponsor_display_id
            : SelectedUser.sponsor_topic_display_id
        }`
      );
      // }
      // window.history.pushState({ view: "SEARCH_RESULT" }, null, null);
      // this.$store.commit("SET_LAST_SEARCH_INPUT", this.SearchInput);
      // this.$store.commit("SET_LAST_RESPONSE_ARRAY", this.FilteredData);
    },
    async ValidateMethod(action) {
      if (this.$refs.SignInForm.validate()) {
        switch (action) {
          case "SEND_OTP":
            try {
              this.LoginLoader = true;
              this.user = await Auth.signIn(`${this.SignIn.countryCode}${this.SignIn.contactNumber}`);
              if (this.user.challengeName === "CUSTOM_CHALLENGE") {
                this.LoginLoader = false;
                this.EnableToastMessage = true;
                this.ResponseColor = "#4ca64c";
                this.ResponseMessage = `OTP sent to ${this.SignIn.countryCode} - ${this.SignIn.contactNumber}`;
                this.$refs.OTPField.reset();
                setTimeout(() => {
                  this.EnableOTPField = true;
                  setTimeout(() => {
                    this.$refs.OTPField.focus();
                  }, 100);
                }, 1000);
                this.$forceUpdate();
              }
            } catch (error) {
              console.log("err", error);
              if (error.message === "DefineAuthChallenge failed with error Error: NOT_AUTHORIZED : Kindly Sigup.") {
                this.SignUpMethod();
                // this.TermsAndConditonDialog = true;
              } else {
                console.log("Err", error);
              }
            }

            break;
          case "VERIFY_OTP":
            try {
              this.LoginLoader = true;
              await Auth.sendCustomChallengeAnswer(this.user, this.SignIn.Otp)
                .then(() => {
                  this.LoginLoader = false;
                  this.drawer = false;
                  this.ActivateMethod();
                })
                .catch((err) => {
                  this.SnackbarComponent = {
                    SnackbarVmodel: true,
                    SnackbarColor: "red",
                    SnackbarText: err.message,
                  };
                  this.LoginLoader = false;
                });
            } catch (error) {
              console.log("err", error);
            }

            break;
        }
      } else {
        this.EnableToastMessage = true;
        this.ResponseColor = "#ff4c4c";
        this.ResponseMessage = "Please enter your mobile number to peroceed..!";
      }
    },
    async SignUpMethod() {
      try {
        var config = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "IjTmpS9QCM8QcrIZZ2pHy64TS6oPdw6A7In2K1M7",
          },
          url: "https://6buq4qbi46.execute-api.ap-south-1.amazonaws.com/Any/consumer_signup",
          data: JSON.stringify({
            command: "createUser",
            consumer_phone_number: `${this.SignIn.countryCode}${this.SignIn.contactNumber}`,
            consumer_creation_source: "PORTAL",
          }),
        };
        let result = await axios(config);
        if (result.data.status === "Success") {
          this.ValidateMethod("SEND_OTP");
        } else if (result.data.errorType === "Error") {
          this.SnackbarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: result.data.errorMessage,
          };
        }
      } catch (error) {
        this.SignUpLoader = false;
        console.log("Errr", error);
      }
    },
    async ActivateMethod() {
      await this.GetCurrentUserMethod();
      this.CurrentUser = JSON.parse(sessionStorage.getItem("SET_CURRENT_USER_DETAILS"));
      if (
        this.CurrentUser.consumer_agreed_terms_and_conditions === undefined ||
        this.CurrentUser.consumer_agreed_terms_and_conditions == false
      ) {
        this.$router.push({ name: "MyProfile" });
      }
    },
    shareProfileViaWhatsApp(expertDetails) {
      const name = expertDetails.expert_name;
      const profession = expertDetails.expert_profession;
      const experience = expertDetails.expert_experience;
      const profileUrl = `anyquire.com/${expertDetails.expert_display_id}`;

      const message = ` Find me on anyquire **${name}** \n**Profession:**${profession}\n**Experience:**${experience}\n**Profile:**${profileUrl}`;

      const encodedMessage = encodeURIComponent(message);

      window.open(`https://wa.me/?text=${encodedMessage}`);
    },
    CommonRouteMethod(value) {
      if (value != "HOME") {
        sessionStorage.setItem("ROUTE_VALUE", value);
        this.$router.push({ name: "MyProfile" });
      }
    },
    async SignOutMethod() {
      try {
        if (this.$route.name !== "HomePage") {
          this.$router.push("/");
        }
        localStorage.clear();
        sessionStorage.clear();
        this.$forceUpdate();
        this.IsUserLoggedIn = false;
        await Auth.signOut({ global: true });
      } catch (error) {
        console.log("Err", error);
      }
    },
  },
};
</script>

<style>
.custom-error-color .v-messages__message {
  color: white !important; /* Change the error message color to white */
}
</style>
