import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    User: "",

    LastSearchInput: "",

    isUserLoggedIn: false,

    LastResponseArray: [],
    GetCurrentuserDetails: {},
    SelectedExpertDetails: {},
  },
  getters: {
    get_user_phone_number: (state) => {
      return state.UserPhoneNumber;
    },
    get_current_user_details: (state) => {
      return state.GetCurrentuserDetails;
    },
    get_selected_expert_details: (state) => {
      return state.SelectedExpertDetails;
    },
    get_search_input: (state) => {
      return state.LastSearchInput;
    },
    get_last_response_array: (state) => {
      return state.LastResponseArray;
    },
    get_user_logged_in_status: (state) => {
      return state.isUserLoggedIn;
    },
  },
  mutations: {
    SET_USER_Number(state, UserPhoneNumber) {
      state.UserPhoneNumber = UserPhoneNumber;
    },
    SET_CURRENT_USER_DETAILS(state, GetCurrentuserDetails) {
      state.GetCurrentuserDetails = GetCurrentuserDetails;
    },
    SET_EXPERT_DETAILS(state, SelectedExpertDetails) {
      state.SelectedExpertDetails = SelectedExpertDetails;
    },
    SET_LAST_SEARCH_INPUT(state, LastSearchInput) {
      state.LastSearchInput = LastSearchInput;
    },
    SET_LAST_RESPONSE_ARRAY(state, LastResponseArray) {
      state.LastResponseArray = LastResponseArray;
    },
    SET_USER_LOGGED_IN(state, isUserLoggedIn) {
      state.isUserLoggedIn = isUserLoggedIn;
    },
  },
  plugins: [createPersistedState()],
  actions: {},
  modules: {},
});
