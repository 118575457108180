import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#6517ce",
        blueColorVariant1: "#2ff7ed",
        blueColorVariant2: "#536872",
        blueColorVariant3: "#3333ff",
        blueColorVariant4: "#3d1f7d",

        greenColorVariant1: "#00ffcc",
        greenColorVariant2:'#00e500',

        redColorVariant:'#ff1919',
        
        pinkColorVariant1: "#ff00cc",

        blackColorVariant1: "#323232",
      },
    },
  },
});
