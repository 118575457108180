<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      clipped
      app
      class="overflow-hidden"
      color="primary"
      bottom
      :height="drawer ? 400 : 0"
    >
      <v-card color="transparent" tile flat>
        <v-form ref="SignInForm" class="pt-5 pb-3">
          <v-row no-gutters dense class="mx-5">
            <v-col cols="5">
              <v-select
                outlined
                dense
                solo
                flat
                class=""
                :items="CountryCodeItems"
                label="Country Code"
                :rules="[(v) => !!v || 'required']"
                v-model="SignIn.countryCode"
              ></v-select>
            </v-col>
            <v-col cols="7">
              <v-text-field
                outlined
                dense
                flat
                solo
                class="ml-2 custom-error-color"
                :rules="[
                  (v) => !!v || 'Mobile number is required',
                  (v) => /^[0-9]{10}$/.test(v) || 'Mobile number must be 10 digits and numeric',
                ]"
                label="Contact Number"
                type="number"
                maxLength="10"
                v-model="SignIn.contactNumber"
                @keypress.native="handleKeyPress"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="text-justify FontSize12px mx-5 white--text" v-if="EnableOTPField">
            Please enter the OTP sent to your mobile number for verification.
          </div>
          <v-otp-input
            v-show="EnableOTPField"
            ref="OTPField"
            autofocus
            length="6"
            class="mx-4"
            type="number"
            v-model="SignIn.Otp"
          ></v-otp-input>
          <v-card-actions class="pa-0 ma-0 mx-5">
            <v-btn
              depressed
              tile
              outlined
              :loading="LoginLoader"
              color="white"
              @click="ValidateMethod(EnableOTPField ? 'VERIFY_OTP' : 'SEND_OTP')"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-navigation-drawer>
    <v-card
      flat
      :height="$vuetify.breakpoint.height - 200"
      v-if="current_view === 'OPEN_SEARCH'"
      class="overflow-hidden"
    >
      <v-app-bar dense flat color="transparent">
        <v-img contain max-width="50px" class="ml-n3" src="@/assets/transparent_logo.png" />
        <v-spacer />
        <v-btn v-if="!IsUserLoggedIn" icon large color="primary" @click="drawer = !drawer"
          ><v-icon>mdi-account-circle</v-icon></v-btn
        >
        <div v-else>
          <v-card-text>
            <div class="FontSize12px text-center">{{ CurrentUser.consumer_phone_number.replace("+91", "+91-") }}</div>
            <div class="FontSize10px text-center mt-n2">
              Balance: {{ Number(CurrentUser.consumer_credits).toFixed(2) }} Credits
            </div>
          </v-card-text>
        </div>
        <v-btn v-if="IsUserLoggedIn" small icon color="red" @click="SignOutMethod()"
          ><v-icon small>mdi-logout</v-icon></v-btn
        >
      </v-app-bar>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex>
            <v-row>
              <v-col cols="12" xs="12" md="12" sm="12" align="center" class="">
                <v-card flat class="text-center" color="transparent">
                  <v-img contain height="100px" src="@/assets/anyqurue-logo.png" />
                  <v-text-field
                    label="Search for providers by keyword"
                    outlined
                    clear-icon="mdi-close"
                    clearable
                    dense
                    v-model="SearchInput"
                    prepend-inner-icon="mdi-magnify"
                    class="FontSize12px mt-3 borderRadiusVariant1"
                    @keydown.enter="SearchResultMethod"
                  ></v-text-field>
                  <v-chip-group column active-class="primary--text" v-model="HomePageChip">
                    <v-chip
                      small
                      flat
                      class="font-weight-bold"
                      v-for="(SearchKey, SearchKeyIndex) in ListSearchKeyWords"
                      :key="SearchKeyIndex"
                      :value="SearchKey"
                    >
                      {{ SearchKey }}
                    </v-chip>
                  </v-chip-group>
                </v-card>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <v-card
      flat
      :height="$vuetify.breakpoint.height - 80"
      class="overflow-auto pb-10"
      v-if="current_view === 'SEARCH_RESULT'"
    >
      <v-card-text>
        <v-row no-gutters dense>
          <v-col cols="4" align="center" class="">
            <v-img max-width="100px" src="@/assets/anyqurue-logo.png" />
          </v-col>
          <v-col cols="7" align="right" class="mt-n1">
            <div v-if="IsUserLoggedIn">
              <div class="FontSize12px text-right">
                {{ CurrentUser.consumer_phone_number.replace("+91", "+91-") }} (User)
              </div>
              <div class="FontSize12px text-right mt-n1">
                Balance: {{ Number(CurrentUser.consumer_credits).toFixed(2) }} Credits
              </div>
            </div>

            <!-- <v-menu offset-y v-else min-width="250px">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar v-on="on" v-bind="attrs" size="40" color="#280952" class="mt">
                  <v-img contain src="@/assets/transparent_logo.png" />
                </v-avatar>
              </template>
              <v-card class="pa-5">
                <div class="text-center FontSize12px">{{ CurrentUser.consumer_phone_number }}</div>
                <div class="text-center FontSize12px"><span>&#8377;</span>{{ CurrentUser.consumer_credits }}</div>
                <v-card-actions class="justify-space-around">
                  <v-btn x-small outlined depressed color="primary" @click="MyProfileMethod()" tile
                    >View My Account</v-btn
                  >
                  <v-btn x-small color="red" depressed dark tile @click="SignOutMethod()">Logout</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu> -->
          </v-col>
          <v-col cols="1">
            <v-btn v-if="!IsUserLoggedIn" icon large color="primary" class="mt-n4" @click="drawer = !drawer"
              ><v-icon>mdi-account-circle</v-icon></v-btn
            >
            <v-btn v-if="IsUserLoggedIn" icon color="red" @click="SignOutMethod()"><v-icon>mdi-logout</v-icon></v-btn>
          </v-col>
          <v-col cols="12">
            <v-text-field
              single-line
              outlined
              dense
              clear-icon="mdi-close"
              clearable
              v-model="SearchInput"
              prepend-inner-icon="mdi-magnify"
              class="FontSize12px mt-3 borderRadiusVariant1"
              @keydown.enter="SearchResultMethod"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mt-n5">
            <v-chip-group v-model="SelectedChip" active-class="primary--text">
              <v-chip label small v-for="category in ListSearchKeyWords" :key="category" :value="category">
                {{ category }}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="12">
            <div
              v-show="ListAllSearchResult.length !== 0"
              v-for="(filteredUser, index) in ListAllSearchResult"
              :key="index"
              class="pb-1"
            >
              <v-card
                flat
                color="#f2f2f2"
                :height="200"
                class="pa-2"
                @click="CheckSelectedUserMethods(filteredUser._source)"
              >
                <v-card-actions class="ma-0 pa-0">
                  <v-avatar size="30" v-if="filteredUser._source.PreSignedURlImage">
                    <v-img :src="filteredUser._source.PreSignedURlImage" />
                  </v-avatar>
                  <v-avatar color="black" size="30" v-else>
                    <v-img src="@/assets/transparent_logo.png" />
                  </v-avatar>
                  <div>
                    <v-card-text class="mt-n2">
                      <div
                        class="FontSize14px font-weight-bold primary--text"
                        v-if="filteredUser._source.expert_service_name && filteredUser._source.search_type === 'EXPERT'"
                      >
                        {{ filteredUser._source.expert_service_name }}
                      </div>
                      <div
                        class="FontSize14px font-weight-bold primary--text"
                        v-if="
                          filteredUser._source.search_type === 'SPONSOR' ||
                          filteredUser._source.search_type === 'SPONSOR_TOPIC'
                        "
                      >
                        {{ filteredUser._source.sponsor_name_of_organization }}
                      </div>
                      <div class="FontSize14px font-weight-bold">
                        {{
                          filteredUser._source.search_type === "EXPERT"
                            ? filteredUser._source.expert_name
                            : filteredUser._source.search_type === "SPONSOR"
                            ? filteredUser._source.sponsor_type_of_organization
                            : filteredUser._source.sponsor_topic_name
                        }}
                      </div>
                    </v-card-text>
                  </div>
                  <v-spacer />
                  <div class="font-weight-bold FontSize12px" v-if="filteredUser._source.search_type === 'EXPERT'">
                    <span v-if="filteredUser._source.expert_charge_per_minute !== 0">&#8377;</span
                    >{{
                      filteredUser._source.expert_charge_per_minute === 0
                        ? "FREE"
                        : `${filteredUser._source.expert_charge_per_minute}/min`
                    }}
                  </div>
                  <div
                    class="font-weight-bold FontSize12px"
                    v-if="filteredUser._source.search_type === 'SPONSOR_TOPIC'"
                  >
                    <span v-if="filteredUser._source.sponsor_topic_charge_per_min !== 0">&#8377;</span
                    >{{
                      filteredUser._source.sponsor_topic_charge_type === "FREE"
                        ? "FREE"
                        : `${filteredUser._source.sponsor_topic_charge_per_min}/min`
                    }}
                  </div>
                </v-card-actions>

                <v-chip-group column class="" v-if="filteredUser._source.search_type === 'EXPERT'">
                  <v-chip
                    x-small
                    v-for="(language, index) in typeof filteredUser._source.expert_languages === 'string'
                      ? JSON.parse(filteredUser._source.expert_languages)
                      : filteredUser._source.expert_languages"
                    :key="index"
                    label
                  >
                    {{ language }}
                  </v-chip>
                </v-chip-group>
                <div class="FontSize10px text-capitalize" v-if="filteredUser._source.search_type === 'EXPERT'">
                  {{ filteredUser._source.expert_state }},{{ filteredUser._source.expert_country }}
                </div>

                <div class="pt-1 FontSize12px text-justify" v-if="filteredUser._source.search_type === 'EXPERT'">
                  {{
                    filteredUser._source.expert_long_description.length <= 120
                      ? filteredUser._source.expert_long_description
                      : `${filteredUser._source.expert_long_description.slice(0, 120)}....`
                  }}
                </div>
                <div class="pt-1 FontSize12px text-justify" v-if="filteredUser._source.search_type === 'SPONSOR'">
                  {{
                    filteredUser._source.sponsor_profile_description.length <= 120
                      ? filteredUser._source.sponsor_profile_description
                      : `${filteredUser._source.sponsor_profile_description.slice(0, 120)}....`
                  }}
                </div>
                <div class="pt-1 FontSize12px text-justify" v-if="filteredUser._source.search_type === 'SPONSOR_TOPIC'">
                  {{
                    filteredUser._source.sponsor_topic_description.length <= 120
                      ? filteredUser._source.sponsor_topic_description
                      : `${filteredUser._source.sponsor_topic_description.slice(0, 120)}....`
                  }}
                </div>
              </v-card>
            </div>
            <div v-if="ListAllSearchResult.length === 0">
              <v-card flat color="transparent" class="overflow-hidden" :height="$vuetify.breakpoint.height - 450">
                <v-container fluid fill-height>
                  <v-layout align-center justify-center>
                    <v-flex>
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" align="center">
                          <v-img contain max-height="500px" src="@/assets/noResultTransparent.png" />
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- <v-footer v-show="!drawer" fixed height="200px" class="">
      <v-row dense no-gutters>
        <v-col cols="7" align="">
          <div class="font-weight-bold">Corporate Info</div>
          <div class="text-left FontSize12px textDecorationVariant1" @click="RouteMethod('TERMS_OF_SERVICE_PORTAL')">
            Terms & Conditions
          </div>
          <div class="text-left FontSize12px textDecorationVariant1" @click="RouteMethod('PRIVACY_PORTAL')">
            Privacy Policy
          </div>
          <div class="text-left FontSize12px textDecorationVariant1">About Us</div>
        </v-col>

        <v-col cols="5" align="">
          <div class="font-weight-bold">Contact Us</div>
          <div class="text-left textDecorationVariant1">
            <v-icon small>mdi-email</v-icon><span class="pl-2 FontSize10px">contactus@anyquire.com</span>
          </div>
          <div class="text-left textDecorationVariant1">
            <v-icon small>mdi-phone</v-icon><span class="pl-2 FontSize10px">+91 - 6366317466</span>
          </div>
        </v-col>

        <v-col cols="12" align="center" :class="IsUserLoggedIn ? 'pb-12' : ''">
          <span class="FontSize12px"
            ><v-icon small class="pr-1 FontSize12px">mdi-copyright</v-icon>{{ new Date().getFullYear() }}
            <span class="FontSize12px">Mobil80 Services and Solutions Pvt. Ltd.</span>
          </span>
          <div class="FontSize12px">All Rights Reserved.</div>
        </v-col>
        <v-col cols="12"> </v-col>
      </v-row>
    </v-footer> -->
    <v-bottom-navigation
      fixed
      color="white"
      hide-on-scroll
      horizontal
      background-color="primary"
      dark
      scroll-target="#scroll-threshold-example"
      scroll-threshold="500"
      v-if="IsUserLoggedIn"
    >
      <v-btn
        x-small
        class="text-capitalize ma-0 pa-0"
        :class="SelectedValue === item.value ? 'white--text' : ''"
        v-for="(item, itemKey) in MenuOptions"
        :key="itemKey"
        @click="CommonRouteMethod(item.value)"
      >
        <v-card-text class="">
          <div class="">
            <v-icon small>{{ item.icon }}</v-icon>
          </div>
          <div class="FontSize12px pt-2">{{ item.text }}</div>
        </v-card-text>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import { GetAllProfession } from "@/mixins/GetAllProfessions.js";
import { GlobalSearch } from "@/mixins/GlobalSearch.js";
import { GetCurrentUser } from "@/mixins/GetUserDetails.js";
import SkeletonLoader from "@/components/Extras/SkeletonLoader.vue";
import { Auth } from "aws-amplify";
import axios from "axios";
export default {
  components: {
    SkeletonLoader,
  },
  mixins: [GetCurrentUser, GlobalSearch, GetAllProfession],
  data: () => ({
    SelectedValue: "SEARCH",
    SelectedChip: "",
    HomePageChip: "",
    SearchTab: 0,
    SearchInput: "",
    TermsAmdConditionSheet: false,
    drawer: false,
    AgreeSignUpLoader: false,
    TermsAndConditonDialog: false,
    TermsAndConditionValue: false,
    EnableSkeltonLoader: false,
    indexName: "",
    EnableToastMessage: false,
    ResponseColor: "",
    ResponseMessage: "",
    EnableOTPField: false,
    LoginLoader: false,
    IsUserLoggedIn: false,
    current_view: "OPEN_SEARCH",
    user: null,
    CurrentUser: {},
    SignIn: {
      countryCode: "+91",
      contactNumber: "",
      Otp: "",
    },
    MenuOptions: [
      {
        text: "Home",
        value: "SEARCH",
        icon: "mdi-magnify",
      },
      {
        text: "Recharge",
        value: "RECHARGE",
        icon: "mdi-lightning-bolt-circle",
      },
      {
        text: "Call History",
        value: "CALL_HISTORY",
        icon: "mdi-phone-plus",
      },
      {
        text: "Transactions",
        value: "TRANSACTION_HISTORY",
        icon: "mdi-currency-rupee",
      },
      {
        text: "Terms",
        value: "TERMS_&_CONDITIONS",
        icon: "mdi-copyright",
      },
    ],
    CountryCodeItems: ["+91"],
  }),
  watch: {
    SearchInput(val) {
      sessionStorage.setItem("LAST_SEARCH", val);
    },
    current_view(val) {
      if (val === "OPEN_SEARCH") {
        this.SearchInput = "";
      }
    },
    HomePageChip(val) {
      if (val != "") {
        this.SearchInput = val;
        this.current_view = "SEARCH_RESULT";
        this.SelectedChip = val;
        this.GlobalSearchMethod(val, "experts");
      }
    },
    SelectedChip(val) {
      if (val != "") {
        this.SearchInput = val;
        this.GlobalSearchMethod(val, "experts");
      }
    },
  },
  computed: {
    GetListProfessionItemMethod() {
      if (this.indexName === "anyquire" || this.indexName === "localhost") {
        return this.listAllProfession;
      } else {
        return this.listSubProfession;
      }
    },
  },
  async mounted() {
    console.log(this.$vuetify);
    // history.pushState(null, null, location.href);
    // window.onpopstate = function () {
    //   history.go(1);
    //   this.SearchInput = "";
    // };
    const hostname = window.location.hostname.toLowerCase().replace("www.", "").split(".")[0];
    this.indexName = hostname;
    // if (sessionStorage.getItem("VISIBLE_CURRENT_PAGE") === "SEARCH_RESULT") {
    //   if (sessionStorage.getItem("LAST_SEARCH") != "" && sessionStorage.getItem("LAST_SEARCH") != null) {
    //     this.SearchInput = sessionStorage.getItem("LAST_SEARCH");
    //     console.log("Check For Last Response", this.ListAllSearchResult);
    //     this.GlobalSearchMethod(
    //       this.SearchInput,
    //       this.indexName === "anyquire" || this.indexName === "localhost" ? "experts" : this.indexName
    //     );
    //   } else {
    //     this.SearchInput = "";
    //   }
    //   this.current_view = "SEARCH_RESULT";
    // } else {
    //   this.current_view = "OPEN_SEARCH";
    //   this.SearchInput = "";
    //   sessionStorage.setItem("VISIBLE_CURRENT_PAGE", "OPEN_SEARCH");
    // }

    this.IsUserLoggedIn = sessionStorage.getItem("SET_USER_LOGGED_IN");
    this.CurrentUser = JSON.parse(sessionStorage.getItem("SET_CURRENT_USER_DETAILS"));
    this.GetAllSearchKeyWordsmethod();
    addEventListener("popstate", (event) => {
      console.log("Event", event);
      this.current_view = "OPEN_SEARCH";
    });
  },
  methods: {
    handleKeyPress(event) {
      if (this.SignIn.contactNumber.length >= 10) {
        event.preventDefault();
      }
    },
    SearchResultMethod() {
      sessionStorage.setItem("VISIBLE_CURRENT_PAGE", "SEARCH_RESULT");
      this.GlobalSearchMethod(
        this.SearchInput,
        this.indexName === "anyquire" || this.indexName === "localhost" ? "experts" : this.indexName
      );
      this.current_view = "SEARCH_RESULT";
    },
    RouteMethod(action) {
      switch (action) {
        case "EXPERT_PORTAL":
          window.open("http://provider.anyquire.com/", "__blank");
          break;
        case "PRIVACY_PORTAL":
          window.open("https://privacypolicy.anyquire.com/", "__blank");
          break;
        case "TERMS_OF_SERVICE_PORTAL":
          window.open("https://termsofservice.anyquire.com/", "__blank");
          break;
      }
    },
    RouteToHomePageMethod() {
      this.current_view = "OPEN_SEARCH";
      this.SearchInput = "";
      this.SelectedChip = "";
      this.HomePageChip = "";
      this.$forceUpdate();
    },
    MyProfileMethod() {
      // window.history.pushState({ view: this.current_view }, null, null);
      this.$router.push("MyProfile");
    },
    CheckSelectedUserMethods(SelectedUser) {
      // if (SelectedUser.search_type === "EXPERT") {
      this.$router.push(
        `${
          SelectedUser.search_type === "EXPERT"
            ? SelectedUser.expert_display_id
            : SelectedUser.search_type === "SPONSOR"
            ? SelectedUser.sponsor_display_id
            : SelectedUser.sponsor_topic_display_id
        }`
      );
      // }
    },
    async ValidateMethod(action) {
      if (this.$refs.SignInForm.validate()) {
        switch (action) {
          case "SEND_OTP":
            try {
              this.LoginLoader = true;
              this.user = await Auth.signIn(`${this.SignIn.countryCode}${this.SignIn.contactNumber}`);
              if (this.user.challengeName === "CUSTOM_CHALLENGE") {
                this.LoginLoader = false;
                this.EnableToastMessage = true;
                this.ResponseColor = "#4ca64c";
                this.ResponseMessage = "OTP sent successfully...!";
                this.$refs.OTPField.reset();
                setTimeout(() => {
                  this.EnableOTPField = true;
                  setTimeout(() => {
                    this.$refs.OTPField.focus();
                  }, 100);
                }, 1000);
                this.$forceUpdate();
              }
            } catch (error) {
              console.log("err", error);
              if (error.message === "DefineAuthChallenge failed with error Error: NOT_AUTHORIZED : Kindly Sigup.") {
                this.SignUpMethod();
              } else {
                console.log("Err", error);
              }
            }

            break;
          case "VERIFY_OTP":
            try {
              this.LoginLoader = true;
              await Auth.sendCustomChallengeAnswer(this.user, this.SignIn.Otp)
                .then(() => {
                  this.LoginLoader = false;
                  this.drawer = false;
                  this.ActivateMethod();
                })
                .catch((err) => {
                  this.SnackbarComponent = {
                    SnackbarVmodel: true,
                    SnackbarColor: "red",
                    SnackbarText: err.message,
                  };
                  this.LoginLoader = false;
                });
            } catch (error) {
              console.log("err", error);
            }

            break;
        }
      } else {
        this.EnableToastMessage = true;
        this.ResponseColor = "#ff4c4c";
        this.ResponseMessage = "Please enter your mobile number to proceed..!";
      }
    },
    async SignUpMethod() {
      try {
        var config = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "IjTmpS9QCM8QcrIZZ2pHy64TS6oPdw6A7In2K1M7",
          },
          url: "https://6buq4qbi46.execute-api.ap-south-1.amazonaws.com/Any/consumer_signup",
          data: JSON.stringify({
            command: "createUser",
            consumer_phone_number: `${this.SignIn.countryCode}${this.SignIn.contactNumber}`,
            consumer_creation_source: "PORTAL",
          }),
        };
        let result = await axios(config);
        if (result.data.status === "Success") {
          this.ValidateMethod("SEND_OTP");
        } else if (result.data.errorType === "Error") {
          this.SnackbarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: result.data.errorMessage,
          };
        }
      } catch (error) {
        this.SignUpLoader = false;
        console.log("Errr", error);
      }
    },
    async ActivateMethod() {
      await this.GetCurrentUserMethod();
      this.CurrentUser = JSON.parse(sessionStorage.getItem("SET_CURRENT_USER_DETAILS"));
      if (this.CurrentUser.consumer_agreed_terms_and_conditions == false) {
        this.$router.push({ name: "MyProfile" });
      }
    },
    shareProfileViaWhatsApp(expertDetails) {
      const name = expertDetails.expert_name;
      const profession = expertDetails.expert_profession;
      const experience = expertDetails.expert_experience;
      const profileUrl = `anyquire.com/${expertDetails.expert_display_id}`;

      const message = ` Find me on anyquire **${name}** \n**Profession:**${profession}\n**Experience:**${experience}\n**Profile:**${profileUrl}`;

      const encodedMessage = encodeURIComponent(message);

      window.open(`https://wa.me/?text=${encodedMessage}`);
    },
    async SignOutMethod() {
      try {
        localStorage.clear();
        sessionStorage.clear();
        this.IsUserLoggedIn = false;
        await Auth.signOut({ global: true });
        this.$store.commit("SET_USER_LOGGED_IN", false);
        if (this.$route.name !== "HomePage") {
          this.$router.push("/");
        }
      } catch (error) {
        console.log("Err", error);
      }
    },
    CommonRouteMethod(value) {
      if (value === "HOME" || value === "SEARCH") {
        this.$router.push("/");
      } else {
        sessionStorage.setItem("ROUTE_VALUE", value);
        this.$router.push({ name: "MyProfile" });
      }
    },
  },
};
</script>

<style>
.custom-error-color .v-messages__message {
  color: white !important; /* Change the error message color to white */
}
</style>
