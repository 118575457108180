<template>
  <v-app>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <MdAndUpFileVue />
    </div>
    <div v-if="$vuetify.breakpoint.mdAndDown">
      <MdAndDownFileVue />
    </div>
  </v-app>
</template>

<script>
import { GetCurrentUser } from "@/mixins/GetUserDetails.js"
import MdAndUpFileVue from "@/components/GlobalSearch/Cards/MdAndUpFile.vue";
import MdAndDownFileVue from "@/components/GlobalSearch/Cards/MdAndDownFile.vue";
export default {
  components: {
    MdAndUpFileVue,
    MdAndDownFileVue,
  },
  mixins:[GetCurrentUser],
  mounted(){
    this.GetCurrentUserMethod()
    // this.$store.commit("SET_CURRENT_USER_DETAILS",)
    // this.$store.commit("SET_USER_LOGGED_IN",false)
    // this.$store.commit("SelectedExpertDetails",{})
    // this.$store.commit("SET_USER_LOGGED_IN",false)
    // this.$store.commit("SET_USER_LOGGED_IN",false)
    console.log('Storage',this.$store.getters)
  },
};
</script>

<style></style>
