import axios from "axios";

export const Uploadile = {
  data: () => ({}),
  methods: {
    async GenerateSignedUrlMethod(key, file) {
      try {
        let data = JSON.stringify({
          command: "generatingS3UploadUrl",
          key: key,
        });
        let config = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "IjTmpS9QCM8QcrIZZ2pHy64TS6oPdw6A7In2K1M7",
          },
          url: "https://6buq4qbi46.execute-api.ap-south-1.amazonaws.com/Any/generatingS3UploadUrl",
          data: data,
        };
        let result = await axios(config);
        if (result.data.status == "Success") {
          return await this.uploadFiletoS3Method(result.data.url, key, file);
        }
        console.log("rresu;lt", result);
      } catch (error) {
        console.log("Err", error);
      }
    },
    async uploadFiletoS3Method(uploadUrl, key, file) {
      try {
        let config = {
          method: "put",
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": file.type,
          },
          url: uploadUrl,
          data: file,
        };
        let result = await axios(config);
        return {
          status: "Success",
          key: key,
        };
      } catch (error) {
        console.log("Err", error);
      }
    },
    async GetPreSignedUrlFilesFromS3(key) {
      try {
        let config = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "IjTmpS9QCM8QcrIZZ2pHy64TS6oPdw6A7In2K1M7",
          },
          url: "https://6buq4qbi46.execute-api.ap-south-1.amazonaws.com/Any/get_presigned_url",
          data: JSON.stringify({ key: key }),
        };
        let result = await axios(config);
        console.log('joo')
        if (result.data.status === "Success") {
          console.log('//////////////////////////////////')
          console.log('URL',result.data)
          console.log('//////////////////////////////////')
          return result.data.url;
        }
      } catch (error) {
        console.log("Err", error);
      }
    },
  },
};
