<template>
  <div>
    <v-sheet color="grey lighten-4" class="pa-3 overflow-hidden mx-16 px-16" outlined width="1250px" :height="$vuetify.breakpoint.height - 200">
      <v-row dense>
        <v-col cols="12" align="left">
          <v-card class="mt-2" >
            <v-skeleton-loader
              class="mx-auto"
              hie
              max-width="1500"
              type="list-item-avatar, list-item-three-line, card-heading"
            ></v-skeleton-loader>
          </v-card>
          <v-card class="mt-2">
            <v-skeleton-loader
              class="mx-auto"
              hie
              max-width="1500"
              type="list-item-avatar, list-item-three-line, card-heading"
            ></v-skeleton-loader>
          </v-card>
          <v-card class="mt-2">
            <v-skeleton-loader
              class="mx-auto"
              hie
              max-width="1500"
              type="list-item-avatar, list-item-three-line, card-heading"
            ></v-skeleton-loader>
          </v-card>
         <!--  <v-card class="mt-2">
            <v-skeleton-loader
              class="mx-auto"
              hie
              max-width="1500"
              type="list-item-avatar, list-item-three-line, card-heading"
            ></v-skeleton-loader>
          </v-card> -->
          <!-- <v-skeleton-loader class="mx-auto" hie max-width="1500" type="article,list-item-two-line divider"></v-skeleton-loader> -->
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
export default {
  data: () => ({
    theme: false,
  }),
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
};
</script>
